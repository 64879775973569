import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Import layout
import Layout from "../layouts/main";

// Import components
import Slider from "../components/Slider";
import MetaData from "../components/MetaData";

// Bootstrap imports
import { Button, Card, Col, Container, Row } from "react-bootstrap";

// Import styles
import "./styles/homepage.scss";

// Icons
import { FaInfoCircle, FaTooth, FaUsers } from "react-icons/fa";

// Import assets
import image from "../assets/images/og-image.jpg";

const IndexPage = () => {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "Homepage" }) {
        id
        homepageFields {
          aboutSection {
            title
            story
          }
          backgroundImage {
            altText
            localFile {
              url
            }
          }
          cards {
            card1 {
              paragraph
              title
              button {
                buttonText
                buttonLink
              }
            }
            card2 {
              paragraph
              title
              button {
                buttonText
                buttonLink
              }
            }
            card3 {
              paragraph
              title
              button {
                buttonText
                buttonLink
              }
            }
          }
        }
        seoFields {
          seo {
            title
            description
          }
        }
      }
    }
  `);

  // Wordpress data
  const { wpPage } = data;

  // Background image
  let backgroundImage = {
    backgroundImage: `url(${wpPage.homepageFields.backgroundImage.localFile.url})`,
  };

  return (
    <Layout>
      <MetaData
        title={wpPage.seoFields.seo.title}
        description={wpPage.seoFields.seo.description}
        image={`https://mountaineer-dental.netlify.app${image}`}
      />
      <div className="homepage">
        <section className="about">
          <div
            className="homepage-intro fade-in-slow"
            style={backgroundImage}
          />
          <div className="overlay" />
          <Container>
            <StaticImage
              className="image"
              src="../assets/images/logo.jpg"
              alt="The Mountaineer Dental logo."
              placeholder="tracedSVG"
            />
            <div className="content">
              <h2 className="shout">
                {wpPage.homepageFields.aboutSection.title}
              </h2>
              <div
                className="talk slide-description"
                dangerouslySetInnerHTML={{
                  __html: wpPage.homepageFields.aboutSection.story,
                }}
              />
            </div>
          </Container>
        </section>
        <section className="cards">
          <div className="title">
            <div className="background-shape" />
            <Container className="fade-in-slow">
              <h1 className="shout">{wpPage.homepageFields.cards.title}</h1>
            </Container>
          </div>
          <div className="card-container">
            <Container>
              <Row>
                <Col className="column" xs={12} sm={6} lg={4}>
                  <Card>
                    <FaUsers />
                    <Card.Body>
                      <h3 className="yell">
                        {wpPage.homepageFields.cards.card1.title}
                      </h3>
                      <p className="talk">
                        {wpPage.homepageFields.cards.card1.paragraph}
                      </p>
                      <Link
                        to={wpPage.homepageFields.cards.card1.button.buttonLink}
                      >
                        <Button variant="accent">
                          {wpPage.homepageFields.cards.card1.button.buttonText}
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="column" xs={12} sm={6} lg={4}>
                  <Card>
                    <FaTooth />
                    <Card.Body>
                      <h3 className="yell">
                        {wpPage.homepageFields.cards.card2.title}
                      </h3>
                      <p className="talk">
                        {wpPage.homepageFields.cards.card2.paragraph}
                      </p>
                      <Link
                        to={wpPage.homepageFields.cards.card2.button.buttonLink}
                      >
                        <Button variant="accent">
                          {wpPage.homepageFields.cards.card2.button.buttonText}
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="column" xs={12} sm={6} lg={4}>
                  <Card>
                    <FaInfoCircle />
                    <Card.Body>
                      <h3 className="yell">
                        {wpPage.homepageFields.cards.card3.title}
                      </h3>
                      <p className="talk">
                        {wpPage.homepageFields.cards.card3.paragraph}
                      </p>
                      <Link
                        to={wpPage.homepageFields.cards.card3.button.buttonLink}
                      >
                        <Button variant="accent">
                          {wpPage.homepageFields.cards.card3.button.buttonText}
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
